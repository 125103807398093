<!-- 经营报表 -->
<template>
  <!-- 看板区域-->
  <div class="warp">
    <el-card class="warp-content">
      <el-row :gutter="20">
        <el-col :span="12">
          <!--  金额曲线图-->
          <div class="moneyClass">
            <div id="chartMoneyDom" ref="chartMoney"></div>
            <el-radio-group
              v-model="radioMoneyValue"
              class="radioMoney"
              @change="selectMoneyType"
            >
              <el-radio label="1" size="large">近七天(周)</el-radio>
              <el-radio label="2" size="large">近七月(月)</el-radio>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :span="12">
          <!--  订单曲线图-->
          <div class="orderClass">
            <div id="chartOrderDom" ref="chartOrder"></div>
            <el-radio-group
              v-model="radioOrderValue"
              class="radioOrder"
              @change="selectOrderType"
            >
              <el-radio label="1" size="large">近七天(周)</el-radio>
              <el-radio label="2" size="large">近七月(月)</el-radio>
            </el-radio-group>
          </div>
        </el-col>
      </el-row>
      <!-- 搜素区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24" style="text-align: right">
            <el-select
              clearable
              class="search-input"
              placeholder="请选择代理商"
              size="small"
              v-model="merchantName"
              @change="hanchangeClick"
              v-if="userLevel == 0"
            >
              <el-option
                v-for="item in paymentTypeList"
                :key="item.merchantId"
                :label="item.merchantName"
                :value="item.merchantId"
              >
                {{ item.merchantName }}
              </el-option>
            </el-select>
            <el-input
              placeholder="请输入设备号"
              class="search-input"
              prefix-icon="search"
              size="small"
              @input="searchInput"
              v-model="deviceMac"
            >
            </el-input>
            <el-button type="primary" size="small" @click="searchClick"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>
      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
          ref="tableRef"
          :data="tableData"
          style="width: 100%"
          max-height="220px"
          :header-cell-style="{ background: '#F2F4F8' }"
          @selection-change="handleSelectionChange"
        >
          <!--     <el-table-column type="selection" align="center" width="80" />-->
          <el-table-column
            v-for="(item, index) in tableColumnData"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            align="center"
          >
          </el-table-column>
          <el-table-column label="操作" align="center" v-if="userLevel == 0">
            <!-- <template #default="{ row }">
              <el-button type="text" @click="editClick(row)">编辑</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="confirmEvent(row)"
                :title="'确认是否删除' + '“' + row.deviceId + '”' + '？'"
              >
                <template #reference>
                  <el-button type="text">删除</el-button>
                </template>
              </el-popconfirm>
            </template> -->
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
          :total="total"
          @pageChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
    </el-card>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getCurrentInstance, onMounted } from "vue-demi";
import { reactive, toRefs } from "vue";
import pageNation from "@/components/base/pageNation";
import {
  selectMoneyApi,
  selectOrderApi,
  selectListApi,
  selectDeviceListApi,
} from "@/api/manageStatement/manageStatement.js";
import { requestApi } from "@/utils/requestApi";
import { debounces } from "@/common/utils";
export default {
  name: "ManageStatement",
  components: { pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      userInfo: {}, // 用户信息
      userLevel: 0, //用户等级
      paymentTypeList: [], //商户列表
      merchantName: null, // 商户绑定
      tableData: [], // 表格数据
      total: 0,
      pageSize: 10,
      currentPage: 1,
      merchantId: null, // 选中的商户id
      stationId: null, // 充电站id
      deviceMac: null, // 设备mac号
      tableColumnData: [
        {
          prop: "deviceMac",
          label: "设备号",
        },
        // {
        //   prop: "oneAgent",
        //   label: "一级代理商",
        // },
        // {
        //   prop: "deviceVersion",
        //   label: "二级代理商",
        // },
        // {
        //   prop: "deviceImei",
        //   label: "三级代理商",
        // },
        {
          prop: "countOrder",
          label: "订单总数",
        },
        {
          prop: "payCardMoney",
          label: "刷卡收入(元)",
        },
        {
          prop: "scanMoney",
          label: "扫码收入(元)",
        },
      ],
      selection: [],
      alarTime: [], //
      xMoneyData: [], // 金额横坐标
      yMoneyData: [], // 金额纵坐标
      radioMoneyValue: "1", // 1 周 2 月
      xOrderData: [], // 金额横坐标
      yOrderData: [], // 金额纵坐标
      radioOrderValue: "1", // 1 周 2 月
    });
    onMounted(() => {
      proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      proxy.$nextTick(() => {
        proxy.userLevel = proxy.userInfo.chargingMerchant.level;
      });
      methods.selectMoney();
      methods.selectOrder();
      if (proxy.userLevel == 0) {
        methods.selectList();
      } else {
        proxy.merchantId = proxy.userInfo.merchantId;
        methods.selectDeviceList();
      }
    });
    // 节流搜索按钮
    const searchInput = debounces(() => {
      methods.selectDeviceList();
    }, 500);
    const methods = {
      // 查询金额折线图
      async selectMoney() {
        await requestApi(
          () => {
            const params = {
              type: Number(data.radioMoneyValue),
              merchantId: proxy.userInfo.merchantId,
            };
            return selectMoneyApi(params);
          },
          (res) => {
            if (res.code === 200) {
              data.xMoneyData = res.data.abscissa;
              data.yMoneyData = res.data.ordinate;
              methods.chartMoney();
            }
          }
        );
      },
      // 查询订单折线图
      async selectOrder() {
        await requestApi(
          () => {
            const params = {
              type: Number(data.radioOrderValue),
              merchantId: proxy.userInfo.merchantId,
            };
            return selectOrderApi(params);
          },
          (res) => {
            if (res.code === 200) {
              data.xOrderData = res.data.abscissa;
              data.yOrderData = res.data.ordinate;
              methods.chartOrder();
            }
          }
        );
      },
      // 页码
      handleSizeChange(val) {
        proxy.pageSize = val;
        methods.selectDeviceList();
      },
      // 分页
      handleCurrentChange(val) {
        proxy.currentPage = val;
        methods.selectDeviceList();
      },
      // 查询商户列表
      async selectList() {
        await requestApi(
          () => {
            const params = {
              merchantId: proxy.userInfo.merchantId,
            };
            return selectListApi(params);
          },
          (res) => {
            if (res.code === 200) {
              proxy.paymentTypeList = res.data;
              if (proxy.paymentTypeList.length > 0) {
                proxy.merchantName = proxy.paymentTypeList[0].merchantId;
                proxy.merchantId = proxy.paymentTypeList[0].merchantId;
                methods.selectDeviceList();
              }
            }
          }
        );
      },
      // 下拉点击事件查询代理商
      hanchangeClick(val) {
        proxy.merchantId = val;
        methods.selectDeviceList();
      },
      // 搜索按钮
      searchClick() {
        methods.selectDeviceList();
      },
      // 查询商户列表
      async selectDeviceList() {
        await requestApi(
          () => {
            const params = {
              current: proxy.currentPage,
              size: proxy.pageSize,
              merchantId: proxy.merchantId,
              stationId: proxy.stationId,
              deviceMac: proxy.deviceMac,
            };
            return selectDeviceListApi(params);
          },
          (res) => {
            if (res.code === 200) {
              proxy.tableData = res.data.records;
              data.currentPage = res.data.current;
              data.pageSize = res.data.size;
              data.total = res.data.total;
            }
          }
        );
      },
      // 充值金额折线图
      chartMoney() {
        let chartDom = proxy.$refs.chartMoney;
        if (chartDom) {
          let myChart = echarts.init(chartDom);
          myChart.option = {
            title: {
              text: "交易金额(元)",
            },
            tooltip: {
              // 提示框组件
              trigger: "axis", // 坐标轴触发
            },
            xAxis: {
              type: "category",
              // nameLocation: 'middle', // 坐标轴名称显示位置
              data: data.xMoneyData,
              axisLine: {
                // 坐标轴轴线
                show: true,
                lineStyle: {
                  color: "#898e91",
                },
              },
              axisTick: {
                // 坐标轴刻度
                alignWithLabel: true,
              },
            },
            yAxis: {
              // name: data.yAxisName,
              // nameTextStyle: { // 坐标轴名称的文字样式
              //   color: '#BBBBBB'
              // },
              splitLine: {
                // 分隔线
                show: true,
                lineStyle: {
                  //
                  color: "rgba(105, 105, 105, 0.4)",
                  width: 1,
                  type: "dashed",
                },
              },
              axisLine: {
                // 坐标轴轴线
                show: true,
                lineStyle: {
                  color: "#898e91",
                },
              },
            },
            grid: {
              top: 40,
              left: 70,
            },
            series: [
              {
                type: "line",
                data: data.yMoneyData,
                smooth: true, //曲线
                areaStyle: data.defaultAreaStyleColor,
                symbol: "none", // 单个数据标记的图形
                symbolSize: 5,
                sampling: "average",
                itemStyle: {
                  // color: 'rgba(34, 231, 229, 1)',
                  normal: data.defaultItemStyle,
                },
              },
            ],
          };
          myChart.option && myChart.setOption(myChart.option);
        }
      },
      // 订单折线图
      chartOrder() {
        let chartDom = proxy.$refs.chartOrder;
        if (chartDom) {
          let myChart = echarts.init(chartDom);
          myChart.option = {
            title: {
              text: "订单数量(笔)",
            },
            tooltip: {
              // 提示框组件
              trigger: "axis", // 坐标轴触发
            },
            xAxis: {
              type: "category",
              // nameLocation: 'middle', // 坐标轴名称显示位置
              data: data.xOrderData,
              axisLine: {
                // 坐标轴轴线
                show: true,
                lineStyle: {
                  color: "#898e91",
                },
              },
              axisTick: {
                // 坐标轴刻度
                alignWithLabel: true,
              },
            },
            yAxis: {
              // name: data.yAxisName,
              // nameTextStyle: { // 坐标轴名称的文字样式
              //   color: '#BBBBBB'
              // },
              splitLine: {
                // 分隔线
                show: true,
                lineStyle: {
                  //
                  color: "rgba(105, 105, 105, 0.4)",
                  width: 1,
                  type: "dashed",
                },
              },
              axisLine: {
                // 坐标轴轴线
                show: true,
                lineStyle: {
                  color: "#898e91",
                },
              },
            },
            grid: {
              top: 40,
              left: 70,
            },
            series: [
              {
                type: "line",
                data: data.yOrderData,
                smooth: true, //曲线
                areaStyle: data.defaultAreaStyleColor,
                symbol: "none", // 单个数据标记的图形
                symbolSize: 5,
                sampling: "average",
                itemStyle: {
                  // color: 'rgba(34, 231, 229, 1)',
                  normal: data.defaultItemStyle,
                },
              },
            ],
          };
          myChart.option && myChart.setOption(myChart.option);
        }
      },
      // 金额近七天  七月的的切换
      selectMoneyType(val) {
        proxy.radioMoneyValue = val;
        methods.selectMoney();
      },
      // 订单近七天  七月的的切换
      selectOrderType(val) {
        proxy.radioOrderValue = val;
        methods.selectOrder();
      },
    };

    return {
      ...toRefs(data),
      searchInput,
      ...methods,
    };
  },
};
</script>

<style lang="less" scoped>
.warp {
  height: 100%;
  .warp-content {
    height: 100%;
  }
}

.page-select {
  width: 100%;
  margin: 0 auto;
}
.page-container {
  width: 100%;
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}
.search-input {
  width: 202px;
  margin-right: 4px;
  margin-top: 6px;
}
.moneyClass {
  height: 100%;
  background: #f2f4f8;
  #chartMoneyDom {
    left: 10px;
    top: 10px;
    width: 35vw;
    height: 35vh;
    float: left;
  }
  .radioMoney {
    width: 50px;
    position: absolute;
    bottom: 10px;
  }
}
.orderClass {
  height: 100%;
  background: #f2f4f8;
  #chartOrderDom {
    width: 35vw;
    height: 35vh;
    left: 10px;
    top: 10px;
  }
  .radioOrder {
    width: 50px;
    position: absolute;
    bottom: 10px;
    right: 80px;
  }
}
</style>
