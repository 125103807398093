import { axios } from '@/utils/request'

const api = {
    money: 'charging/merchantBalance/os/money', // 查询金额柱状图
    order: 'charging/merchantBalance/os/order', // 查询订单柱状图
    selectList:'charging/merchant/selectList', //查询商户列表
    selectDeviceList:'charging/station/selectDeviceList' //查询分页
}
export default api

// 查询金额
export const selectMoneyApi = (params) => {
    return axios({
        url: api.money,
        method: 'post',
        params
    })
}

// 查询订单
export const selectOrderApi = (params) => {
    return axios({
        url: api.order,
        method: 'post',
        params
    })
}

// 查询商户列表
export const selectListApi = (params) => {
    return axios({
        url: api.selectList,
        method: 'post',
        params
    })
}

// 查询分页
export const selectDeviceListApi = (params) => {
    return axios({
        url: api.selectDeviceList,
        method: 'post',
        params
    })
}