<template>
  <el-pagination v-model:currentPage="currentPage"
                 :page-sizes="[10, 30, 50, 100]"
                 :page-size="pageSize"
                 layout="total, sizes, prev, pager, next, jumper"
                 :total="total"
                 @size-change="handleSizeChange"
                 @current-change="$emit('handleCurrentChange')"
                 >
  </el-pagination>
</template>

<script>
import { reactive, toRefs } from 'vue-demi'
export default {
  name: 'pageNation',
  props:{
    total:{
        type:Number
    }
  },
  setup(prop,context) {
    const pageData = reactive({
      currentPage: 1,
      pageSize: 10,
    })
    const methods = {
      handleSizeChange(val) {
          pageData.pageSize=val
          context.emit('pageChange',pageData.pageSize)
      },
      handleCurrentChange(val){
          pageData.currentPage=val
          context.emit('currentChange', pageData.currentPage)
      }
    }
    return {
      ...toRefs(pageData),
      ...methods,
    }
  },
}
</script>

<style>
</style>